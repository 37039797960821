import { convertProjectDetailToQueryParam, Helpers } from '../../helpers/Helpers';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IGraphQueryParam } from 'src/app/Models/Graph';
import { AuthGaurdGuard } from 'src/app/helpers/Auth-gurad/auth-gaurd.guard';
import { AdminService } from 'src/app/admin/admin-service/admin.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public location: Location;
  public currentProjectLink: string;
  public clientName: string;
  public proid: string;
  public hideClient: boolean;

  constructor(
    public authGuard: AuthGaurdGuard,
    location: Location,
    public router: Router,
    public helperService: Helpers,
    public authi: AdminService,
    private sharedService: SharedService
  ) {
    this.location = location;
  }


  ngOnInit() {
    //this.projectDashboardLink();
    this.sharedService.currentProjectId.subscribe(projectId => {
      this.proid = projectId;
      this.hideClient = this.showClient();
    });
  }

  public get dashboardLink(): string {
    return this.helperService.dashboardLink;
  }

  public get projectDashboardLink(): string {
    return sessionStorage.getItem("projectDashboardLink");
  }

  get queryParam()
  {
    if(this.helperService.getitem("projectDetails"))
    {
      return convertProjectDetailToQueryParam(JSON.parse(this.helperService.getitem("projectDetails")));
    }
    else
      return  { projectId: this.authGuard.projectId };

  }
 
  public logout() {
    return this.helperService.logout();
  }

  isShowDataInput(): boolean {
    if(!this.authGuard.projectId && !this.helperService.hasProjectWrite(this.authGuard.projectId) && !this.proid)
      return false;
    
    if (this.location.path().split('?')[0] == '/admin/graph')
      return true;
    else if (this.location.path().split('?')[0].includes('data-input'))
      return true;
    else if (this.location.path().split('?')[0] == '/admin/dashboard')
      return false;
    else
      return true;
  }

  showDashboard(): boolean {
    if (!this.authGuard.projectId && !this.proid)
      return false;
    else
      return true;
  }

  showOptions(): boolean {
    if (!this.authGuard.projectId && !this.proid) 
      return false;
    else 
      return true;
  }

  showClient(): boolean {
    if (!this.proid) {
      return true;
    }
    else {
      this.fetchClientName(this.proid);
      return false;
    }
  }

  fetchClientName(proid) {
    if (proid) {
      this.authi.getProjectData(Number(proid)).subscribe(
        (res: any) => {
          this.clientName = res.data.clientName;
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }
}
